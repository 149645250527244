<template>
    <a-layout class="common">
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <div class="logo">
                <img src="@/assets/logo.png" alt />
            </div>
            <a-row class="slideBox">
                <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys">
                    <template v-for="(navItem, navIndex) in navList" :key="navItem.path">
                        <a-sub-menu v-if="navItem.children && navItem.meta.isShow" :key="navItem.path">
                            <template #icon>
                                <icon-font class="indexIconFont" v-if="navItem.meta && navItem.meta.icon" :type="`icon-${navItem.meta.icon}`" />
                            </template>
                            <template #title>{{ navItem.name }}</template>
                            <template v-for="(subNavItem, navIndex) in navItem.children" :key="subNavItem.path">
                                <a-menu-item v-if="subNavItem.meta.isShow" :key="subNavItem.path" @click="handleGo(subNavItem.path)">
                                    <icon-font class="indexIconFont" v-if="subNavItem.meta && subNavItem.meta.icon" :type="`icon-${subNavItem.meta.icon}`" />
                                    <span>{{ subNavItem.name }}</span>
                                </a-menu-item>
                            </template>
                        </a-sub-menu>
                        <a-menu-item v-if="!navItem.children && navItem.meta.isShow" :key="navItem.path" @click="handleGo(navItem.path)">
                            <icon-font class="indexIconFont" v-if="navItem.meta && navItem.meta.icon" :type="`icon-${navItem.meta.icon}`" />
                            <span>{{ navItem.name }}</span>
                        </a-menu-item>
                    </template>
                </a-menu>
            </a-row>
        </a-layout-sider>
        <a-layout>
            <a-layout-header class="CommonHeader">
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
                <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
                <a-row class="projectName">{{projectName}}</a-row>
                <a-button type="link" style="marginRight:100px" @click="() => (visible = !visible)">
                    小程序码
                    <icon-font type="icon-xiaochengxu1" />
                </a-button>
                <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent>
                        {{loginName}}
                        <icon-font type="icon-renyuan" />
                    </a>
                    <template #overlay>
                        <a-menu>
                            <a-menu-item @click="handleLogout">
                                <icon-font type="icon-tuichu" />退出登录
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </a-layout-header>
            <a-layout-content class="CommonMain">
                <a-spin :spinning="isLoading" size="large" tip="加载中...">
                    <router-view />
                </a-spin>
            </a-layout-content>
        </a-layout>
        <a-modal v-model:visible="visible" :footer="null" title="小程序码" :bodyStyle="{textAlign:'center'}">
            <img style="width:230px;padding:50px 0" src="../assets/xcx.jpg" alt="小程序码">
        </a-modal>
    </a-layout>
</template>
<script>
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, getCurrentInstance, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
    components: {
        MenuUnfoldOutlined,
        MenuFoldOutlined,
    },
    setup() {
        //初始化数据
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const store = useStore();
        //左侧菜单数据列表
        const navList = ref([]);
        const routerList = ref([]);
        routerList.value = proxy.$router.options.routes.filter(
            (i) => i.path == "/Index"
        );
        routerList.value[0].children.forEach(item => {
            if (item.meta.isShow != undefined && item.path != '/VideoIndex' && item.path != '/ConfigChapter' && item.path != '/ConfigKnowledge') {
                item.meta.isShow = store.state.loginInfo.role_type == 2 ? false : true
            }
            // if (store.state.loginInfo.role_type == 2) {
            //     if ((item.path == '/ConfigChapter' || item.path == '/ConfigKnowledge')) item.meta.isShow = true
            // }
        })
        navList.value = routerList.value[0].children;

        // 加载中
        let isLoading = computed(() => {
            return store.state.loading;
        });
        // 导航菜单点击事件
        const handleGo = (pathCur) => {
            router.push(pathCur);
        };
        const handleLogout = () => {
            proxy.http.post('/login/logout').then(() => {
                router.push('/');
                sessionStorage.clear();
                store.dispatch('setUpdateLoginInfo', {})
                store.dispatch('setUpdateToken', '')
            })
        }
        return {
            selectedKeys: ref([proxy.$router.currentRoute.value.path]),
            projectName: proxy.projectName,
            openKeys: ref(["/VideoIndex"]),
            loginName: store.state.loginInfo.realname,
            collapsed: ref(false),
            navList,
            isLoading,
            handleGo,
            handleLogout,
            visible: ref(false),
        };
    },
});
</script>
<style lang="scss" scoped>
.common {
    height: 100vh;
    .slideBox {
        height: calc(100% - 110px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .slideBox::-webkit-scrollbar {
        display: none;
    }
    .logo {
        width: 100%;
        height: 85px;
        text-align: center;
        padding: 18px 0;
        box-sizing: border-box;
        margin-bottom: 10px;
        > img {
            height: 100%;
            width: auto;
        }
    }

    .CommonHeader {
        background: #ffffff;
        padding: 0px 24px;
        display: flex;
        align-items: center;
        .trigger {
            font-size: 18px;
            padding-right: 24px;
            cursor: pointer;
            transition: color 0.3s;
        }
        .projectName {
            margin-right: auto;
            color: #333;
            letter-spacing: 1px;
            font-size: 20px;
        }
    }
    .CommonMain {
        margin: 20px;
        padding: 0;
        background: #fff;
        min-height: 280px;
        overflow: auto;
    }
}
</style>
